<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item active" aria-current="page">Pengembalian</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-3 mb-4">
					<SidebarPanduan />
				</div>
				
				<div class="col-md-9 mb-4">
					<div class="card shadow border-0 text-secondary round px-5 py-4">
						<div class="text-center mb-4">
							<h2>Pengembalian</h2>
							<img src="@/assets/images/pengembalian.jpg" alt="pengembalian.jpg" class="w-75 round shadow">
						</div>
						<div class="text-left">
							<p>Pengembalian barang dapat  dilakukan apabila memang kesalahan dari pihak kami, dan untuk pengembalian barang dapat dilaporkan dalam waktu satu minggu setelah barang diterima.</p>
							<p>Anda dapat mengembalikan barang yang dibeli di Duniamurah.com dengan alasan yang dapat diterima yaitu:</p>
							<ul>
								<li>Cacat produksi</li>
								<li>Tidak berfungsi sebagaimana mestinya (<em>mal function</em>)</li>
								<li>Tidak sesuai dengan informasi di website</li>
								<li>Parts tidak lengkap</li>
								<li>Produk yang diterima tidak sesuai dengan pesanan</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>
import SidebarPanduan from '@/components/SidebarPanduan.vue'

export default {
	components: {
  		SidebarPanduan
	},
	created() {
		$(document).scrollTop(0)
	} 
}
</script>